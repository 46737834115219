.thankyou-container {
    position: absolute;
    height: 138vh;
    background: #000000c7;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10000000000000;
}

.thankyou-box {
    border-radius: 170px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-image: linear-gradient(to right, #f3f2ee, #948f7ac9);
    width: 230px;
}

.thankyou-text {
    font-size: 28px;
    font-weight: bolder;
    font-family: -webkit-pictograph;
    color: black;
}

.cancel-btn-link {
    color: white !important;
}

.cancel-btn-link :hover {
    color: #303346 !important;
}

.cancel-btn-link:hover,
.auth-verify-btn:hover,
.cancel-btn-link:active,
.cancel-btn-link:visited {
    background-color: white !important;
    color: #303346;
}