.footer-container {
    background-color: #fff;
    border-top: 1px solid #e4e4e4;
    padding: 60px 0 0 0;
    width: 100%;
}

.site-links h2 {
    font-size: 15px;
    margin: 0 0 1rem;
    font-weight: 700;
    color: #4a4a4a;
}

.site-links p {
    font-size: 18px;
    font-size: 13px;
    padding: 4px 0;
    color: #4a4a4a;
}

.social-link p {
    padding-left: 12px;
}

.social-links i {
    font-size: 26px;
    padding: 18px;
    border-radius: 50%;
    margin: 2px;
    background-color: #f3f3f3;
    color: #4a4a4a;
    transition: all .2s ease;
    height: 60px;
    width: 60px;
    text-align: center;
}

.social-links i:hover {
    background-color: #eb3223;
    color: #fff;
}

.social-links p {
    font-size: 14px;
    margin-bottom: 13px;
    color: #4a4a4a;
    line-height: 1.25;
    margin-top: 27px;
    font-weight: 400;
}

.social-links img {
    width: 37%;
}

.social-links .apple {
    height: 47px;
    border-radius: 7px;
    margin-top: 2px;
    margin-left: 8px
}

.footer-social-box {
    border-left: 1px solid #e4e4e4;
    padding-left: 10px;
}

.copyright-footer {
    background-color: #fafafa;
    border-top: 1px solid #e4e4e4;
    height: 128px;
}

.copyright-footer ul li {
    font-size: 12px;
    letter-spacing: -.01rem;
    line-height: 1.2;
    margin: 0;
    padding: 16px;
    list-style: none;
}

.copyright-footer p {
    font-size: 12px;
    margin: 0;
    list-style: none;
}

.footer-logo {
    height: 24px;
    margin-right: 8px;
    margin-top: 8px;
}