 .setting-container {
     border-radius: 30px 30px 30px 30px;
     box-shadow: 0px 0px 11px #0000005c;
 }
 
 .winnercard h2 {
     color: white;
     background-color: #9b29ff;
     text-align: center;
     padding: 13px;
     font-size: 26px;
     border-radius: 30px 30px 0px 0px;
 }
 
 .winner-image {
     width: 154px;
     left: 88px;
 }
 
 detail-image {
     width: 50% !important;
 }
 
 .detail-text {
     font-size: 17px;
     font-weight: 600;
     letter-spacing: 1px;
     margin-top: 19px;
 }
 
 .prize-badge {
     height: 80px;
     position: absolute;
     right: 36px;
     top: 0px;
     opacity: 0.9;
 }
 
 .winnercard span {
     letter-spacing: -.2px;
     color: #4a4a4a;
     margin: 6px 0 0;
     font-size: 12px;
     font-weight: 400;
     text-align: center;
     padding: 5px;
 }
 
 .winner-announced-date {
     line-height: 14px;
     letter-spacing: -.2px;
     font-weight: 400;
     color: #4a4a4a;
     margin: 0 0 22px;
     font-size: 12px;
     text-align: center;
     padding-bottom: 18px;
 }
 
 .winnercard h4 {
     font-family: Rubik Medium, Helvetica, Arial, sans-serif;
     letter-spacing: -.3px;
     font-weight: 400;
     color: #2f85e9;
     font-size: 16px;
     line-height: 18px;
     text-align: center;
     margin-top: 19px;
     margin-bottom: 0;
     padding: 5px;
 }
 
 .winnercard p {
     color: #4a4a4a;
     font-size: 1.4rem;
     line-height: 24px;
     letter-spacing: -.4px;
     text-align: center;
     margin: 0 16px 6px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     font-weight: 400;
 }
 
 .back-arrow {
     font-size: 23px;
     font-weight: 900;
     color: black;
 }
 
 @media only screen and (max-width: 600px) {
     .detail-image {
         width: 56% !important;
         margin-top: 157px !important;
     }
 }