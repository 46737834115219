#navbar-mobile-container #app-actions {
    justify-content: space-evenly;
    border-left: 2px solid #f3f2ee;
}


/* .header__logo {} */

.header__logo a {
    display: inline-block;
}

.header__menu {
    text-align: center;
    padding: 26px 0 25px;
}

.header__menu ul li {
    list-style: none;
    display: inline-block;
    margin-right: 45px;
    position: relative;
}

.header__menu ul li.active a:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.header__menu ul li:hover a:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.header__menu ul li:hover .dropdown {
    top: 32px;
    opacity: 1;
    visibility: visible;
}

.header__menu ul li:last-child {
    margin-right: 0;
}

.header__menu ul li .dropdown {
    position: absolute;
    left: 0;
    top: 56px;
    /* width: 150px; */
    background: transparent;
    text-align: left;
    padding: 5px 0;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all, 0.3s;
    -o-transition: all, 0.3s;
    transition: all, 0.3s;
}

.header__menu ul li .dropdown li {
    display: block;
    margin-right: 0;
}

.header__menu ul li .dropdown li a {
    font-size: 14px;
    color: #ffffff;
    font-weight: 400;
    padding: 5px 20px;
    text-transform: capitalize;
}

.header__menu ul li .dropdown li a:after {
    display: none;
}

.header__menu ul li a {
    font-size: 18px;
    color: #707070;
    display: block;
    font-weight: 600;
    position: relative;
    padding: 3px 0;
}

.header__menu ul li a:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #707070;
    content: "";
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.header__nav__option {
    text-align: right;
    padding: 30px 0;
}

.header__nav__option a {
    display: inline-block;
    margin-right: 26px;
    position: relative;
}

.header__nav__option a span {
    color: #0d0d0d;
    font-size: 11px;
    position: absolute;
    left: 5px;
    top: 8px;
}

.header__nav__option a:last-child {
    margin-right: 0;
}

.header__nav__option .price {
    font-size: 15px;
    color: #707070;
    font-weight: 700;
    display: inline-block;
    margin-left: -20px;
    margin-right: 10px;
    position: relative;
    top: 3px;
}

.offcanvas-menu-wrapper {
    display: none;
}

.canvas__open {
    display: none;
}

@media (min-width: 375px) and (max-width: 812px) {
    #mobile-header {
        padding: 10px;
    }
    #app-nav {
        position: fixed;
        background: white;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 9999;
        padding: 0;
    }
    #navbar-mobile-container {
        justify-content: space-between;
    }
    #navbar-mobile-container #app-brand {
        flex: 1;
    }
    #navbar-mobile-container #app-actions {
        flex: 1;
    }
    .header__menu ul {
        display: flex;
        justify-content: space-around;
    }
    .header__menu ul li {
        margin-right: 0;
    }
}

.dropdown-toggle::after {
    display: none;
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: unset !important;
}

.dropdown-item a {
    color: #212529 !important;
}

.dropdown-item a:hover {
    color: #212529 !important;
}