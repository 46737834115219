.hero-container {
    background-image: linear-gradient(to right bottom, #333333, #333);
    padding: 53px;
    clip-path: polygon(0 0, 100% 0%, 100% 81%, 0% 100%);
}

.hero-left {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding-top: 78px;
    z-index: 200;
}

.mobile-image {
    width: 301px;
}

.hero-right {
    position: relative;
}

.playstore-image {
    position: absolute;
    width: 120px;
    left: 159px;
    top: 87px;
    opacity: .7;
}

.applestore-image {
    position: absolute;
    width: 120px;
    left: 159px;
    top: 138px;
    opacity: .7;
}

.hero-heading-top p {
    font-size: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: left;
    color: #fff;
}

.hero-heading-bottom p {
    font-size: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: right;
    color: #fff;
    top: 2rem;
    right: 2rem;
    position: relative;
}

.card__heading-span--1 {
    background-image: linear-gradient(to right bottom, #050505a6, #30334670);
}

.card__heading-span--2 {
    background-image: linear-gradient(to right bottom, #050505a6, #30334670);
}

.hero-sub-heading p {
    color: #A4A5AD;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    width: 561px;
    z-index: 99;
    position: relative;
    letter-spacing: 2px;
    top: 48px;
}

.hero-btn {
    margin-top: 30px;
    z-index: 99;
    position: relative;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .hero-heading-bottom p {
        font-size: 1.8rem;
        font-weight: 300;
        text-transform: uppercase;
        color: #fff;
        position: relative;
        top: 19px;
        text-align: center;
    }
    .hero-heading-top p {
        font-size: 1.8rem;
    }
    .hero-sub-heading p {
        width: 434px;
        font-size: 13px;
    }
    .mobile-image {
        width: 187px;
        position: relative;
        top: 41px;
    }
    .playstore-image {
        position: absolute;
        width: 73px;
        left: 106px;
        top: 87px;
        opacity: .7;
    }
    .applestore-image {
        position: absolute;
        width: 73px;
        left: 106px;
        top: 122px;
        opacity: .7;
    }
}

@media (min-width: 280px) and (max-width: 767px) {
    .hero-container {
        /* background: #303346; */
        background-image: linear-gradient(to right bottom, #303346a6, #303346f7);
        padding: 53px;
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 81%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);
        height: 114vh;
    }
    .hero-heading-bottom p {
        font-size: 1.3rem;
        font-weight: 300;
        text-transform: uppercase;
        color: #fff;
        position: relative;
        top: 19px;
        text-align: center;
        width: 134%;
    }
    .hero-heading-top p {
        font-size: 1.3rem;
    }
    .hero-sub-heading p {
        width: 100%;
        font-size: 13px;
    }
    .mobile-image {
        width: 187px;
        position: relative;
        top: 35px;
        left: -30px;
    }
    .playstore-image {
        position: absolute;
        width: 73px;
        left: 75px;
        top: 78px;
        opacity: .7;
    }
    .applestore-image {
        position: absolute;
        width: 73px;
        left: 75px;
        top: 115px;
        opacity: .7;
    }
}