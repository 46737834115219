.invalid-tooltip {
    font-size: 10px !important;
    left: 28px;
    background: #ff8a60 !important;
}

.auth-box {
    padding: 0px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    margin: 0px;
    border: unset
}

.auth-body {
    padding: 16px 20px;
}

.auth-footer {
    display: flex;
    padding: 25px;
    flex-wrap: wrap;
}

.auth-header-btn-left {
    height: 100px;
    width: 50%;
    border-radius: 30px 0px 0px 0px;
    margin-bottom: 20px;
    border-right: unset;
    margin: 0px;
    font-size: 32px;
    font-weight: bold;
    color: white;
    transition: .2s ease-in-out;
}

.auth-header-btn-right {
    height: 100px;
    width: 50%;
    border-radius: 0px 30px 0px 0px;
    margin-bottom: 20px;
    border-left: unset;
    margin: 0px;
    font-size: 32px;
    font-weight: bold;
    color: white;
    transition: .4s ease-in-out;
}

.auth-input {
    border-radius: 50px;
    padding: 25px 22px;
    margin-top: 20px;
}

.auth-radio {
    color: white;
    font-size: 11px;
    flex: 1;
}

.auth-login-btn {
    padding: 10px;
    width: 50%;
    border-radius: 50px;
    background-color: white;
    color: #303346;
    font-size: 16px;
    font-weight: 700;
    border: unset;
    flex: 1;
}

.auth-login-btn:hover {
    background-color: white;
    color: #303346;
}

.auth-verify-btn {
    padding: 10px;
    width: 100%;
    border-radius: 18px;
    background-color: #303346;
    color: white;
    font-size: 12px;
    font-weight: 700;
    border: 2px solid white;
}

.auth-header-btn-right:hover,
.auth-header-btn-right:active,
.auth-header-btn-right:visited,
.auth-header-btn-left:hover,
.auth-header-btn-left:active,
.auth-header-btn-left:visited {
    background-color: white !important;
    color: black;
}

.auth-select {
    border-radius: 50px;
    height: 53px;
}

@media only screen and (min-width: 200px) and (max-width: 768px) {
    .auth-header-btn-right {
        font-size: 13px;
    }
    .auth-header-btn-left {
        font-size: 13px;
    }
    .auth-radio {
        margin-bottom: 17px;
    }
}

.custom-control-label::before {
    top: 0 !important;
}